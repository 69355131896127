







import { defineComponent, reactive } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

export default defineComponent({
  props: {
    // vehicle ID
    id: {
      type: String,
      required: true
    }
  },

  setup(props, { root, emit }) {
    const state = reactive({
      loading: false
    })

    const deleteComment = () => {
      const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

      state.loading = true

      axiosInstance
        .delete(`comment/${props.id}`)
        .then(() => emit('delete'))
        .catch(error => console.log(error))
        .finally(() => state.loading = false)

    }
  

    return { state, deleteComment }
  }
})
